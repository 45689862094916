import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch ,Redirect  } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Category from '../Category/Category';
import Pick from '../Pick/Pick';
import Login from '../Login/Login';
import PrivateRoute from '../../Utils/PrivateRoute';
import SubmitOrder from "../SubmitOrder/SubmitOrder";

function App() {

    return (
        <div className="App">
            <BrowserRouter>
                <div className="page">
                    <Switch>
                        <Redirect exact from="/" to="/login" />
                        <Route path="/login" component={Login} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/category/:id" component={Category}/>
                        <PrivateRoute path="/pick/:category/:id" component={Pick} />
                        <PrivateRoute path="/review/:id" component={SubmitOrder} />
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;