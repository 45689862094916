import React, {useEffect, useState} from 'react';
import './Review.css';

const Review = props => {

    const {orderItems} = props;
    const {pickingCategory} = props;
    const [totalMissingLines, setTotalMissingLines] = useState();
    const [totalSubstituteLines, setTotalSubstituteLines] = useState();
    const [summary , setSummary] = useState([]);

    useEffect(() => {
        let totalMissingLines = 0;
        let totalSubstituteLines = 0;
        let summaryItems = [];
         orderItems.forEach((products, index) => {
            products.forEach((item, i) => {
                let aisle = item.category;
                let expectedLines = 0;
                let pickedLines = 0;
                let substituteLines = 0;
                let missingLines = 0;
                if(item.category === "Additional Products"){
                    item.items.forEach((product, i) => {
                        pickedLines = pickedLines + product.qty_picked;
                    })
                }
                else{
                    item.items.forEach((product, i) => {
                        expectedLines = expectedLines + parseInt(product.qty_ordered);
                        pickedLines = pickedLines + product.qty_picked;
                        product.substitutelines.forEach((sub_product, i) => {
                            substituteLines = substituteLines + parseInt(sub_product.qty);
                        })
                        if((product.qty_picked === 0 && product.substituted_qty === 0) || product.unavailable){
                            missingLines = missingLines + 1;
                        }
                    })
                }
                totalMissingLines = totalMissingLines + missingLines;
                totalSubstituteLines = totalSubstituteLines + substituteLines;
                summaryItems.push({
                    aisle: aisle,
                    expectedLines: expectedLines,
                    pickedLines: pickedLines,
                    substituteLines: substituteLines,
                    missingLines: missingLines
                });
                setTotalMissingLines(totalMissingLines);
                setTotalSubstituteLines(totalSubstituteLines);
            });
        });
        setSummary(summaryItems);
    },[orderItems]);

    const reviewNotes = () => {
        if(totalSubstituteLines > 0 || totalMissingLines > 0){
            let reviewNotes = "Please Note:";
            let substituteItems = "";
            let missingLines = "";
            let connector = "";
            if(totalSubstituteLines){
                 substituteItems = totalSubstituteLines+ " Substitute Lines";
            }
            if(totalMissingLines){
                 missingLines = totalMissingLines+ " Missing Lines";
            }
            if(totalSubstituteLines && totalMissingLines){
                connector = " and ";
            }
            return (
                <div className="notes">
                    <div className="title">{reviewNotes}</div>
                    <div className="content">There are <span className="red"> {missingLines}</span>{connector}<span className="yellow"> {substituteItems}</span></div>
                </div>
            );
        }
    }

    return (
        <div>
            <h2 className="summary__title">Summary ({pickingCategory}) </h2>
            
            <table>
                <thead>
                    <tr>
                        <th>Aisle</th>
                        <th>Expected Lines</th>
                        <th>Picked Lines</th>
                        <th>Substitute Lines</th>
                        <th>Missing Lines</th>
                    </tr>
                </thead>
                <tbody>
                {summary.map((summaryItem, index) => (
                    <tr>
                        <td>{summaryItem.aisle}</td>
                        <td>{summaryItem.expectedLines}</td>
                        <td>{summaryItem.pickedLines}</td>
                        <td>{summaryItem.substituteLines}</td>
                        <td>{summaryItem.missingLines}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className="review-notes">
                {reviewNotes()}
            </div>
        </div>
    );
}

export default Review;
