import React, {useState} from 'react';
import './AddAdditionalProducts.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCameraRetro, faKeyboard} from "@fortawesome/free-solid-svg-icons";
import Alert from "@material-ui/lab/Alert";
import Fade from "@material-ui/core/Fade";
import $ from "jquery";
import {getScanProductApiEndPoint} from "../../Utils/Common";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Search from "../Search/Search";

const AddAdditionalProducts = props => {

    const {orderItems} = props;
    const {onClick} = props;
    const history = useHistory();
    const {additionalProductsCategoryLabel} = props;
    const [showMessage, setShowMessage] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const showScanner = () => {
        setShowMessage(true);
        $(".add-scan").removeClass("fade");
        document.getElementsByClassName('add-additional-input')[0].focus({
            preventScroll: true
        });
        setTimeout(function() {
            $('.add-additional-input').attr('inputmode','text');
        }, 10);
        setTimeout(function() {
            setShowMessage(false);
        }, 1000);
    }

    const searchProduct = () => {
        setShowSearch(true);
    }

    const close = () => {
        onClick();
    }

    $('.add-additional-input').off("input change").on("input change", function(e) {
        let barcode = $('.add-additional-input').val();
        if (barcode.length > 8 && barcode!=="") {
            addProduct(barcode);
            setTimeout(function() {
                clearBarcode();
            }, 20);
        }
    });

    const clearBarcode = () => {
        $('.add-additional-input').val("");
    }

    const addProduct = (result) => {
        if(result){
            let productCode = result;
            const scanProductApiEndPoint = getScanProductApiEndPoint();
            axios.post(scanProductApiEndPoint, { productCode: productCode, }).then(response => {
                if(response.data.length){
                    addItem(response.data[0]);
                }
            }).catch(error => {
                if(error.response.data.message && error.response.data.message === "The consumer isn't authorized to access %resources."){
                    history.push("/login");
                }
            });
        }
    }

    const addItem = (updatedItem) => {
        orderItems.forEach(
            (products) => {
                let lineIndex = products.findIndex(el => el.category === additionalProductsCategoryLabel);
                if(lineIndex === -1){
                    let itemData = {
                        category : additionalProductsCategoryLabel,
                        items: [{
                            name: updatedItem.name,
                            sku: updatedItem.sku,
                            item_price: updatedItem.price,
                            image_url: updatedItem.image_url,
                            product_code: updatedItem.product_code,
                            product_id: updatedItem.product_id,
                            pack_size: updatedItem.pack_size,
                            additional_item: true,
                            fully_picked: true,
                            qty_picked: 1,
                        }],
                        'total_items':1,
                        'remaining_items':0
                    };
                    products.push(itemData);
                }
                else{
                    let item = products.find(product => product.category === additionalProductsCategoryLabel);
                    let indexToUpdate = item.items.findIndex(obj => obj.product_id === updatedItem.product_id);
                    if(indexToUpdate >= 0){
                        let OrderItemToUpdate = item.items[indexToUpdate];
                        OrderItemToUpdate.qty_picked = OrderItemToUpdate.qty_picked + 1;
                    }
                    else{
                        let itemToAdd = {
                            name: updatedItem.name,
                            sku: updatedItem.sku,
                            item_price: updatedItem.price,
                            image_url: updatedItem.image_url,
                            product_code: updatedItem.product_code,
                            product_id: updatedItem.product_id,
                            pack_size: updatedItem.pack_size,
                            additional_item: true,
                            fully_picked: true,
                            qty_picked: 1,
                        };
                        products[lineIndex].items.push(itemToAdd);
                    }
                }
            });
        props.onChange(orderItems);
        close();
    }

    const refreshItems = () =>{
        props.onChange(orderItems);
    }

    $('.add-additional-input').on("blur", function() {
        $('.add-additional-input').attr('inputmode','none');
        $(".add-scan").addClass("fade");
    });

    return (
        <div className="add-additional-products">
            <span onClick={onClick} className="close">Close</span>
            <h4>Add Additional Products</h4>
            <div className="search sub-search">
                {showSearch && <Search productCategory={additionalProductsCategoryLabel} additionalProductsCategoryLabel={additionalProductsCategoryLabel} orderItems ={orderItems} key="code_search" addItem= {addItem} onChange={refreshItems}/>}
            </div>
            <div className="add-actions" id="add-actions">
                <div className="action add-scan fade" onClick={() => showScanner()}>
                    <label>Scan Product</label>
                    <FontAwesomeIcon size='2x' icon={faCameraRetro}/>
                </div>
                <div className="action" onClick={(e) => searchProduct()}>
                    <label>Enter Code</label>
                    <FontAwesomeIcon size='2x' icon={faKeyboard}/>
                </div>
            </div>
            <div>
                <input type="text" className="add-additional-input hideScannerInput" autoFocus inputMode="none"/>
            </div>
            <Fade in={showMessage} >
                <Alert variant="filled" severity="success">
                    Ready to Scan!
                </Alert>
            </Fade>
        </div>
    )

}

export default AddAdditionalProducts;