import React, {useEffect, useState} from 'react';
import './Recommendations.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import {getRecommendationsApiEndPoint, getToken} from "../../Utils/Common";
import axios from "axios";
import useForceUpdate from 'use-force-update';
import Zoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css';
const Recommendations = props => {
    const {productItem} = props;
    const {productCategory} = props;
    const {orderItems} = props;
    const [loading, setLoading] = useState(false);
    const [recommendations,setRecommendations] = useState([]);
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        const token = getToken();
        const apiUrl = getRecommendationsApiEndPoint(productItem.product_id);
        setLoading(true);
        const getRecommendations = async () => {
            axios.get(
                apiUrl,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            ).then((response) => {
                setLoading(false);
                if(response.data && response.status === 200 ){
                    setRecommendations(response.data);
                }
            });
        };
        getRecommendations();
    }, [productItem.product_id]);

    const updateQty = (updatedItem,mode,qtyToAdd) => {
        if(mode === "increment"){
            updatedItem.qty = updatedItem.qty + 1;
        }
        if(mode === "decrement"){
            if(updatedItem.qty > 0){
                updatedItem.qty = updatedItem.qty - 1;
            }
        }
        if(mode === "add"){
            updatedItem.qty = qtyToAdd;
        }
        orderItems.forEach(
            ( products ) => {
                let item = products.find(product => product.category === productCategory);
                let indexToUpdate = item.items.findIndex(obj => obj.product_id === productItem.product_id);
                let OrderItemToUpdate = item.items[indexToUpdate];
                if (OrderItemToUpdate.product_id === productItem.product_id) {
                    let itemToAdd = {
                        name: updatedItem.name,
                        sku: updatedItem.sku,
                        price:updatedItem.price,
                        image_url: updatedItem.image_url,
                        product_code: updatedItem.product_code,
                        product_id: updatedItem.product_id,
                        qty: updatedItem.qty,
                        parent_id: productItem.product_id
                    };
                    OrderItemToUpdate.substituted_qty = updatedItem.qty;
                    OrderItemToUpdate.substitutelines[0] = itemToAdd;
                    setFullyPicked(OrderItemToUpdate);
                    /*const found = OrderItemToUpdate.substitutelines.some(el => el.product_id === updatedItem.product_id);
                    if (found) {
                        let lineIndex = OrderItemToUpdate.substitutelines.findIndex(obj => obj.product_id === updatedItem.product_id);
                        OrderItemToUpdate.substitutelines[lineIndex] = itemToAdd;
                    } else {
                        OrderItemToUpdate.substitutelines.push(itemToAdd);
                    }*/
                }
            });

        setRecommendations(recommendations);
        props.onChange(orderItems);
        forceUpdate();
    }

    const setFullyPicked = (productItem) =>{
        if(productItem.qty_picked + productItem.substituted_qty >= productItem.qty_ordered ){
            productItem.fully_picked = true
        }
        else{
            productItem.fully_picked = false
        }
    }

    if (loading) {
        return <p>Data is loading...</p>;
    }
    return (
        <div>
            {!recommendations.length
                ? <div>No substitutions found.</div>
                : recommendations.map((item, index) => (
                    <div className="item-info" key={"item_"+item.product_id}>
                        <div className="topBanner show" key={"rec_"+item.product_id}>
                            <div className="item-image">
                                <Zoom>
                                    <img
                                        alt={item.name}
                                        src={item.image_url}
                                        width="50"
                                    />
                                </Zoom>
                            </div>
                            <div className="item-desc">
                                <div>
                                    <span className="item-name">{item.name}</span><br/>
                                    <span className="item-sku">Code: {item.product_code}</span><br/>
                                    <span className="item-price">Price: {item.price}</span>
                                </div>
                                <div className="item-qty">
                                    <div className="add-minus-quantity">
                                        <FontAwesomeIcon size='2x' className="inc" icon={faPlusSquare} onClick={(e) => updateQty(item,"increment","") }/>
                                        <input type="text"  placeholder={item.qty} value={item.qty} onChange={e => updateQty( item,"add",(parseInt(e.target.value) || 0))} className="qty-input"/>
                                        <FontAwesomeIcon size='2x' className="inc" icon={faMinusSquare} onClick={(e) => updateQty(item,"decrement","") }/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Recommendations;