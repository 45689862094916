import React, {useEffect, useState} from 'react';
import {useParams,useHistory} from "react-router-dom";
import {
    getPickingCategoriesApiEndPoint,
    getToken,
    getStartPickingUpdateApiEndPoint,
    getUser,
    getAdminId,
    setPickingId
} from "../../Utils/Common";
import axios from "axios";
import Header from "../Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlayCircle,faLock} from '@fortawesome/free-solid-svg-icons'
import './Category.css';
import {Link} from "@material-ui/core";
import {trackPromise} from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import {confirmAlert} from "react-confirm-alert";
function Category() {
    const { id } = useParams();
    const token = getToken();
    const pickerId = getAdminId();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        let orderId = id;
        let apiToken = getToken();
        const apiUrl = getPickingCategoriesApiEndPoint(orderId);
        const getCategories = async () => {
            trackPromise(
                axios.get(
                    apiUrl,{
                        headers: {
                            'Authorization': `Bearer ${apiToken}`
                        }
                    }
                ).then((response) => {
                    if(response.data){
                        setCategories(response.data);
                    }
                }).catch(error => {
                    let errorMessage = error.response.data.message;
                    if(errorMessage === "The consumer isn't authorized to access %resources."){
                        history.push("/login");
                    }
                })
            )
        };
        getCategories();
    }, [id,history]);

    const confirm = (category) => {
        let  path = `/pick/${category.label}/${id}`;
        let  apiToken = token;
        let  pickerName = getUser();
        let  pickerId = getAdminId();
        let  pickingOrderUpdateApiEndPoint = getStartPickingUpdateApiEndPoint();
        let dataToPost = [{"orderId":id,"customer_email":category.customer_email,"picking_category":category.label,"picker_name":pickerName,"picker_id":pickerId}];
        setLoader(true);
        axios.post(pickingOrderUpdateApiEndPoint, dataToPost,{
                headers: {
                    'Authorization': `Bearer ${apiToken}`
                }
            }).then((response) => {
                if(response.data !== ""){
                    setLoader(false);
                    setPickingId(response.data);
                    history.push(path);
                }
            }).catch(error => {
                let errorMessage = error.response.data.message;
                if(errorMessage === "The consumer isn't authorized to access %resources."){
                    history.push("/login");
                }
            })
    }

    const back = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to exit the order?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        history.push("/dashboard");
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    return (

        <div>
            <Header/>
            <div className="title__section">
                <div className="title__inner">
                    <p>Order: #{id}</p>
                    <Link onClick={() => back()} className="button--secondary">Back</Link>
                </div>
            </div>
            {loader ? <Loader type="ThreeDots" color="#008863" height="100" width="100" className="loading-spinner"/> :

                <div className="order-categories">
                    {categories.map((category, index) => (
                        <div className="cat-wrapper">
                            {category.is_locked && pickerId !== category.picker_id ?
                                <div className="locked">
                                    <div className="icon">
                                        <FontAwesomeIcon size='2x' icon={faLock}/>
                                    </div>
                                    <div>
                                        <strong>Pick {category.label}</strong>
                                    </div>
                                    <div>
                                        <strong>(Currently Being Picked By {category.picker_name})</strong>
                                    </div>
                                    <span>{category.total_items}</span>
                                    <span>{category.picked_items}</span>
                                </div>
                                :
                                category.picking_completed ?
                                <div className="locked">
                                    <div className="icon">
                                        <FontAwesomeIcon size='2x' icon={faLock}/>
                                    </div>
                                    <div>
                                        <strong>Pick {category.label}</strong>
                                    </div>
                                    <div>
                                        <strong>(Picking Completed)</strong>
                                    </div>
                                    <span>{category.total_items + ' ' + category.picked_items}</span>
                                </div>
                                    :
                                    <div className="unlocked" onClick={() => confirm(category)}>
                                        <div className="icon">
                                            <FontAwesomeIcon size='2x' icon={faPlayCircle}/>
                                        </div>
                                        <div className="categoryLabel">
                                            <strong>
                                                <span>Pick {category.label}</span>
                                            </strong>
                                        </div>
                                        <span>{category.total_items} ({category.picked_items})</span>
                                    </div>
                            }
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}
export default Category;