import React,{useState,useEffect} from 'react';
import {getToken, getPickingOrdersApiEndPoint,getPickedOrdersApiEndPoint, isStoreManager} from '../../Utils/Common';
import axios from 'axios';
import Table from "../Table/Table";
import './Orders.css';
import {Link} from "@material-ui/core";
import {trackPromise} from "react-promise-tracker";
import {useHistory} from "react-router-dom";
function Orders() {
    const [tableData,setTableData] = useState([]);
    const [loader,setLoader] = useState(false);
    const [storeManager,setStoreManager] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const storeManager = isStoreManager();
        const orderApiEndPoint = storeManager ? getPickedOrdersApiEndPoint() : getPickingOrdersApiEndPoint();
        const token = getToken();
        setStoreManager(storeManager);
        const fetchOrders = async () => {
            setLoader(true);
            trackPromise(
                axios.get(
                    orderApiEndPoint,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                ).then((response) => {
                    let responseObject = JSON.parse(response.data);
                    if(responseObject.status === "ok"){
                        setLoader(false);
                        let orders = [];
                        responseObject.orders.forEach((data,id)=>{
                            let orderData = {
                                orderId: data.increment_id,
                                name:data.customer_firstname,
                                items:data.total_line_items,
                                date: data.am_delivery_date + ' ' + data.am_delivery_time,
                                status:data.status === "picking" ? "Picking" : data.status === "parked" ? "Order Parked" : data.status === "processing" ? "Order Received" : data.status === "picked" ? "Order Picked" : data.status,
                                actions:storeManager ? "Review" : "Pick"
                            };
                            orders.push(orderData);
                        })
                        setTableData(orders);
                    }
                }).catch(error => {
                    let errorMessage = error.response.data.message;
                    if(errorMessage === "The consumer isn't authorized to access %resources."){
                        history.push("/login");
                    }
                })
            )
        };
        fetchOrders();
    }, [history,storeManager]);

    return (
        <div>
            {loader ? "" :
                <div className="orders__wrapper">
                    <h1 className="--has-tagline">Picking</h1>
                    <p className="header__tagline">If not all data is visible, drag the table to see more</p>

                    <Table
                        searchable={true}
                        columns={[
                            { title: "Order Id", field: "orderId", type: "numeric" ,render:rowData=> <div className='OrderName'>{rowData.orderId} <span>{rowData.name}</span></div>,cellStyle: {minWidth: 100}},
                            { title: "Line Items", field: "items",cellStyle: {maxWidth: 5} },
                            { title: "Delivery Date", field: "date" ,defaultSort: "asc",cellStyle: {minWidth:70}},
                            { title: "Status", field: "status"},
                            { title: "Actions", field: "actions",render:rowData=> storeManager ? <Link href={`/review/${rowData.orderId}`}>{rowData.actions}</Link> : <Link href={`/category/${rowData.orderId}`}>{rowData.actions}</Link>},
                        ]}
                        data={tableData}
                    />
                </div>
            }
        </div>
    );
}

export default Orders;