import React from 'react';
import './Header.css';
import {getLogoutApiEndPoint, getUser, removeUserSession,getAdminId} from '../../Utils/Common';
import { useHistory } from "react-router-dom";
import axios from "axios";
import {confirmAlert} from "react-confirm-alert";
function Header(props) {
    const history = useHistory();
    const pickerId = getAdminId();

    // handle click event of logout button
    const handleLogout = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to logout?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let logoutEndpoint = getLogoutApiEndPoint();
                        axios.post(logoutEndpoint, { admin_id: pickerId }).then(response => {
                            if(response.data){
                                removeUserSession();
                                history.push('/login');
                            }
                        }).catch(error => {
                            //setError("Something went wrong. Please try again later.");
                        });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    return (
        <div className="header__top">
            Welcome {getUser()}!
            <input type="button" onClick={handleLogout} value="Logout" />
        </div>
    );
}

export default Header;
