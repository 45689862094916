import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import React from "react";
/**
 *
 * @param props
 * @returns {boolean|JSX.Element}
 * @constructor
 */
const LoadingIndicator = props => {
      const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&
        <div>
            <Loader type="ThreeDots" color="#008863" height="100" width="100" className="loading-spinner"/>
        </div>
    );
}

export default LoadingIndicator;