// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return userStr;
    else return null;
}

// return the token from the session storage
export const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

// return the role from the session storage
export const getRole = () => {
    return sessionStorage.getItem('role') || null;
}

//check if store manager is logged IN
export const isStoreManager = () => {
    let role = sessionStorage.getItem('role') || null;
    if(role === "Store Manager"){
        return true;
    }
    else{
        return false;
    }
}

// return the adminId from the session storage
export const getAdminId = () => {
    return sessionStorage.getItem('admin_id') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('admin_id');
    sessionStorage.removeItem('picking_id');
}

// set the token and user from the session storage
export const setUserSession = (userData) => {
    sessionStorage.setItem('token', userData.token);
    sessionStorage.setItem('user', userData.name);
    sessionStorage.setItem('role', userData.role);
    sessionStorage.setItem('admin_id', userData.admin_id);
}

export const setPickingId = (pickingId) => {
    sessionStorage.setItem('picking_id', pickingId);
}

export const getPickingId = () => {
    return sessionStorage.getItem('picking_id') || null;
}

export const getRestApiUrl = () =>{
    return "https://www.riteway.vg/rest/V1/";
}

export const getLoginApiEndPoint = () => {
    return getRestApiUrl()+"login";
}

export const getLogoutApiEndPoint = () => {
    return getRestApiUrl()+"logout";
}

export const getPickingOrdersApiEndPoint = () => {
    return getRestApiUrl() + "fetch_orders/picker";
}

export const getPickedOrdersApiEndPoint = () => {
    return getRestApiUrl() + "fetch_orders/manager";
}

export const getLoadOrderApiEndPoint = (orderId) => {
    return getRestApiUrl()+"order_detail/"+orderId;
}

export const getRecommendationsApiEndPoint = (productId) => {
    return getRestApiUrl()+"recommendations/"+productId;
}

export const getPickingCategoriesApiEndPoint = (orderId) => {
    return getRestApiUrl()+"picking_categories/"+orderId;
}

export const getCustomerDetailsApiEndPoint = (orderId) => {
    return getRestApiUrl()+"customer_info/"+orderId;
}

export const getProductSearchApiEndPoint = (productCode) => {
    return getRestApiUrl()+"picking_search/"+productCode;
}

export const getStartPickingUpdateApiEndPoint = () => {
    return getRestApiUrl()+"start_picking/";
}

export const getParkOrderApiEndPoint = () => {
    return getRestApiUrl()+"park_order/";
}

export const getCompletePickingApiEndPoint = () => {
    return getRestApiUrl()+"complete_picking/";
}

export const getScanProductApiEndPoint = () => {
    return getRestApiUrl()+"scan/";
}

export const getSubmitOrderApiEndPoint = () => {
    return getRestApiUrl()+"submit_order/";
}

export const getCancelPickingApiEndPoint = () => {
    return getRestApiUrl()+"cancel_picking/";
}
