import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faPauseCircle} from "@fortawesome/free-solid-svg-icons";
import {confirmAlert} from "react-confirm-alert";
import {getAdminId, getCompletePickingApiEndPoint, getParkOrderApiEndPoint, getPickingId} from "../../Utils/Common";
import axios from "axios";
import './PickingActions.css';
import {useHistory} from "react-router-dom";
import Loader from "react-loader-spinner";
import Review from "../Review/Review";
import $ from 'jquery';


const PickingActions = props => {
    const { orderItems } = props;
    const { orderId } = props;
    const { pickingCategory } = props;
    const history = useHistory();
    const [review, setReview] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const park = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to park the order?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const parkOrderApiEndPoint = getParkOrderApiEndPoint();
                        axios.post(parkOrderApiEndPoint, { orderId:orderId,pickerId:getAdminId(),orderData: orderItems,pickingId:getPickingId(),pickingCategory:pickingCategory }).then(response => {
                            if(response.data){
                                setLoader(false);
                                setError(false);
                                history.push('/category/'+orderId);
                            }
                        }).catch(error => {
                            if(error.response && error.response.data.message && error.response.data.message === "The consumer isn't authorized to access %resources."){
                                history.push("/login");
                            }
                            else{
                                setError(true);
                                setLoader(false);
                                document.getElementById("review").classList.toggle("show");
                            }
                        });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const finish = () => {
        document.getElementById("order-items").classList.add("hide");
        document.getElementById("order-comments").classList.add("hide");
        document.getElementById("picking-actions").classList.add("hide");
        document.getElementById("review").classList.add("show");
        document.getElementById("order-items").classList.remove("show");
        document.getElementById("review").classList.remove("hide");
        setReview(!review);
    }

    const scan = (productCode) => {
        if(productCode !== "") {
            let productFound = false;
            let productID = "";
            orderItems.forEach(
                ( products ) => {
                    products.forEach(
                        ( item ) => {
                            let indexToUpdate = item.items.findIndex(obj => obj.product_code === productCode);
                            if(typeof(indexToUpdate) !== 'undefined' && indexToUpdate != null && indexToUpdate >= 0){
                                let OrderItemToUpdate = item.items[indexToUpdate];
                                if(OrderItemToUpdate.qty_picked < OrderItemToUpdate.qty_ordered){
                                    OrderItemToUpdate.qty_picked = OrderItemToUpdate.qty_picked + 1;
                                    productID = OrderItemToUpdate.product_id;
                                    checkIfFullyPicked(OrderItemToUpdate);
                                }
                                productFound = true;
                            }
                            else {
                                item.items.forEach(
                                    (subs) => {
                                        const lineIndex = subs.substitutelines.findIndex(obj => obj.product_code === productCode);
                                        if (typeof (lineIndex) !== 'undefined' && lineIndex != null && lineIndex >= 0) {
                                            let QtyToUpdate = subs.substitutelines[lineIndex].qty;
                                            productFound = true;
                                            subs.substitutelines[lineIndex].qty = QtyToUpdate + 1;
                                            let indexToUpdate = item.items.findIndex(obj => obj.product_id === subs.substitutelines[lineIndex].parent_id);
                                            if(typeof(indexToUpdate) !== 'undefined' && indexToUpdate != null && indexToUpdate >= 0) {
                                                productID = item.items[indexToUpdate].product_id;
                                                checkIfFullyPicked(item.items[indexToUpdate]);
                                            }
                                        }
                                    })
                            }
                        })
                });
            if(productFound){
                $('html, body').animate({
                    scrollTop: $("#prod_banner_"+productID).offset().top
                }, 500);
                props.onChange(orderItems);
            }
        }
    }

    const checkIfFullyPicked = (productItem) =>{
        if(productItem.qty_picked + productItem.substituted_qty >= productItem.qty_ordered ){
            productItem.fully_picked = true
        }
        else{
            productItem.fully_picked = false
        }
    }

    const clearBarcode = () => {
        $('.scanner-input').val("");
    }

    const confirm = () =>{
        confirmAlert({
            title: '',
            message: 'Are you sure you want to submit the order?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoader(true);
                        let completePickingApiEndPoint = getCompletePickingApiEndPoint();
                        axios.post(completePickingApiEndPoint, { orderId:orderId,pickerId:getAdminId(),orderData: orderItems,pickingId:getPickingId() }).then(response => {
                            if(response.data){
                                setLoader(false);
                                history.push('/category/'+orderId);
                            }
                        }).catch(error => {
                            let errorMessage = error.response.data.message;
                            if(errorMessage === "The consumer isn't authorized to access %resources."){
                                history.push("/login");
                            }
                        });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const cancel = () =>{
        confirmAlert({
            title: '',
            message: 'Are you sure you want to cancel the submission?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        document.getElementById("review").classList.add("hide");
                        document.getElementById("order-items").classList.remove("hide");
                        document.getElementById("review").classList.remove("show");
                        history.push('/category/'+orderId);
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    $('.scanner-input').off("input change").on("input change", function(e) {
        let barcode = $('.scanner-input').val();
        if (barcode.length > 8 && barcode!=="") {
            scan(barcode);
            setTimeout(function() {
                clearBarcode();
                }, 20);
        }
    });

    $('.scanner-input').on("blur", function() {
        $('.scanner-input').attr('inputmode','none');
        $(".scan").addClass("fade");
    });

    return (
        <div>
            <div>
                <input type="text" className="scanner-input hideScannerInput" autoFocus inputMode="none"/>
            </div>
        <div id="picking-actions">
            <div className="scan fade">
            </div>

            <div className="actions">
                <div className="park" onClick={() => park()}>
                    <label>Park</label>
                    <FontAwesomeIcon icon={faPauseCircle} size='2x' />
                </div>

                <div className="finish"  onClick={() => finish()}>
                    <label>Finish</label>
                    <FontAwesomeIcon icon={faCheckCircle} size='2x'/>
                </div>
            </div>
        </div>
            { error ? <span className="error">Oops, Something has gone wrong</span> : ''}
            <div id="review" className="review hide">
                {loader ? <Loader type="ThreeDots" color="#008863" height="100" width="100"  className="loading-spinner" /> : review && <Review orderItems={orderItems} pickingCategory={pickingCategory}/> }
                <div className="actions">
                    <div className="cancel" onClick={() => cancel()}>
                        <label>Cancel</label>
                        <FontAwesomeIcon icon={faPauseCircle} size='2x'/>
                    </div>

                    <div className="confirm" onClick={() => confirm()}>
                        <label>Confirm</label>
                        <FontAwesomeIcon icon={faCheckCircle} size='2x'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickingActions;