import React, {useEffect, useState} from 'react';
import './SubmitOrder.css';
import Header from "../Header/Header";
import {Link} from "@material-ui/core";
import Customer from "../Customer/Customer";
import {useParams} from "react-router-dom";
import {getLoadOrderApiEndPoint, getToken, getCancelPickingApiEndPoint,getSubmitOrderApiEndPoint} from "../../Utils/Common";
import {trackPromise} from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faPauseCircle} from "@fortawesome/free-solid-svg-icons";
import {confirmAlert} from "react-confirm-alert";
import Loader from "react-loader-spinner";

function SubmitOrder() {
    const { id } = useParams();
    const [orderData, setOrderData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [orderItems, setOrderItems] = useState([]);
    const [totalMissingLines, setTotalMissingLines] = useState();
    const [totalSubstituteLines, setTotalSubstituteLines] = useState();
    const history = useHistory();
    useEffect(() => {
        const token = getToken();
        let orderId = id;
        const apiUrl = getLoadOrderApiEndPoint(orderId);
        const getOrder = async () => {
            trackPromise(
                axios.get(
                    apiUrl,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                ).then((response) => {
                    if(response.data){
                        setOrderItems(response.data);
                        let category = "";
                        let orderSummary = [];
                        let totalMissingLines = 0;
                        let totalSubstituteLines = 0;

                        response.data.forEach((summary, index) => {
                            category = summary.picking_category;
                            let summaryItems = [];
                            summary.products.forEach((item, i) => {
                                let aisle = item.category;
                                let expectedLines = 0;
                                let pickedLines = 0;
                                let substituteLines = 0;
                                let missingLines = 0;
                                item.items.forEach((product, i) => {
                                    if(product.additional_item){
                                        pickedLines = pickedLines + product.qty_picked;
                                    }
                                    else{
                                        expectedLines = expectedLines + parseInt(product.qty_ordered);
                                        pickedLines = pickedLines + product.qty_picked;
                                        product.substitutelines.forEach((sub_product, i) => {
                                            substituteLines = substituteLines + parseInt(sub_product.qty);
                                        })
                                        if((product.qty_picked === 0 && product.substituted_qty === 0) || product.unavailable){
                                            missingLines = missingLines + 1;
                                        }
                                    }

                                })
                                totalMissingLines = totalMissingLines + missingLines;
                                totalSubstituteLines = totalSubstituteLines + substituteLines;
                                summaryItems.push({
                                    aisle: aisle,
                                    expectedLines: expectedLines,
                                    pickedLines: pickedLines,
                                    substituteLines: substituteLines,
                                    missingLines: missingLines
                                });
                                setTotalMissingLines(totalMissingLines);
                                setTotalSubstituteLines(totalSubstituteLines);
                            });
                            orderSummary.push({category:category,items:summaryItems});
                        })
                        setOrderData(orderSummary);
                    }
                }).catch(error => {
                    let errorMessage = error.response.data.message;
                    if(errorMessage === "The consumer isn't authorized to access %resources."){
                        history.push("/login");
                    }
                })
            )
        };
        getOrder();
    }, [id,history]);

    const reviewNotes = () => {
        if(totalSubstituteLines > 0 || totalMissingLines > 0){
            let reviewNotes = "Please Note:";
            let substituteItems = "";
            let missingLines = "";
            let connector = "";
            if(totalSubstituteLines){
                substituteItems = totalSubstituteLines+ " Substitute Lines";
            }
            if(totalMissingLines){
                missingLines = totalMissingLines+ " Missing Lines";
            }
            if(totalSubstituteLines && totalMissingLines){
                connector = " and ";
            }
            return (
                <div className="notes">
                    <div className="title">{reviewNotes}</div>
                    <div className="content">There are <span className="red"> {missingLines}</span>{connector}<span className="yellow"> {substituteItems}</span></div>
                </div>
            );
        }
    }

    const cancel = () =>{
        confirmAlert({
            title: '',
            message: 'Are you sure you want to cancel the order, this will put the order back in parked state?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoader(true);
                        let cancelOrderApiEndPoint = getCancelPickingApiEndPoint();
                        axios.post(cancelOrderApiEndPoint, { orderId:id }).then(response => {
                            if(response.data) {
                                if (response.data[0]['success']) {
                                    setLoader(false);
                                    history.push('/dashboard');
                                } else {
                                    setError(true);
                                }
                                setLoader(false);
                            }
                        }).catch(error => {
                            if(error.response && error.response.data.message && error.response.data.message === "The consumer isn't authorized to access %resources."){
                                history.push("/login");
                            }
                            else{
                                setError(true);
                                setLoader(false);
                                document.getElementById("review").classList.toggle("show");
                            }
                        });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const confirm = () =>{
        confirmAlert({
            title: '',
            message: 'Are you sure you want to submit the order?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoader(true);
                        let submitOrderApiEndPoint = getSubmitOrderApiEndPoint();
                        axios.post(submitOrderApiEndPoint, { orderId:id,orderData: orderItems }).then(response => {
                            if(response.data) {
                                if (response.data[0]['success']) {
                                    setLoader(false);
                                    setErrorMessage('');
                                    history.push('/dashboard');
                                } else {
                                    setError(true);
                                    setErrorMessage("Error -" + response.data[0]['errorMessage']);
                                }
                                setLoader(false);
                            }
                        }).catch(error => {
                             /*if(error.response && error.response.data.message && error.response.data.message === "The consumer isn't authorized to access %resources."){
                               history.push("/login");
                             }
                             else{
                                 setError(true);
                                 setLoader(false);
                                 document.getElementById("review").classList.toggle("show");
                             }*/
                        });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }


    return (
        <div>
            <Header/>
            <div>
                <div className="title__section">
                    <div className="title__inner">
                        <p>Order: #{id}</p>
                        <Link href="/dashboard" className="button--secondary">Back</Link>
                    </div>
                    <Customer orderId={id} key='key_picking_customer'/>
                </div>
            </div>
            { error ? <span className="errorMessage">Oops, Something has gone wrong! {errorMessage} </span> : ''}
            {loader ? <Loader type="ThreeDots" color="#008863" height="100" width="100" className="loading-spinner"/> :
            <div id="final_review">
                {orderData.map((data, index) => (
                    <div>
                        <h2 className="summary__title">Summary ({data.category}) </h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Aisle</th>
                                    <th>Expected Lines</th>
                                    <th>Picked Lines</th>
                                    <th>Substitute Lines</th>
                                    <th>Missing Lines</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.items.map((summaryItem, index) => (
                                    <tr>
                                        <td>{summaryItem.aisle}</td>
                                        <td>{summaryItem.expectedLines}</td>
                                        <td>{summaryItem.pickedLines}</td>
                                        <td>{summaryItem.substituteLines}</td>
                                        <td>{summaryItem.missingLines}</td>
                                    </tr>
                                ))}
                                </tbody>
                        </table>
                    </div>
                ))}
                <div className="review-notes">
                    {reviewNotes()}
                </div>
                <div className="actions">
                    <div className="cancel">
                        <label>Cancel</label>
                        <FontAwesomeIcon icon={faPauseCircle} size='2x' onClick={() => cancel()}/>
                    </div>
                    <div className="confirm">
                        <label>Confirm</label>
                        <FontAwesomeIcon icon={faCheckCircle} size='2x' onClick={() => confirm()}/>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default SubmitOrder;