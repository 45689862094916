import React from 'react';

import Header from "../Header/Header";
import Orders from "../Orders/Orders";

function Dashboard() {

    return (
        <div>
           <Header/>
           <Orders/>
        </div>
    );
}

export default Dashboard;
