import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import logo from './logo.svg';
import {setUserSession, getLoginApiEndPoint} from '../../Utils/Common';

function Login(props) {
    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);

    // handle button click of login form
    const handleLogin = () => {
        const loginEndpoint = getLoginApiEndPoint();
        setError(null);
        setLoading(true);
        axios.post(loginEndpoint, { username: username.value, password: password.value }).then(response => {
            if(response.data.token){
                setUserSession(response.data);
                props.history.push('/dashboard');
            }
        }).catch(error => {
            setLoading(false);
            setError("Something went wrong. Please try again later.");
        });
    }

    return (
        <div className="login__page">
            <div className="login__form">
                <img src={logo} alt="Roadtown Logo" />
                <h1>Login</h1>
                <div>
                    <input type="text" {...username} autoComplete="new-password" placeholder="Username" />
                </div>
                <div>
                    <input type="password" {...password} autoComplete="new-password" placeholder="Password" />
                </div>
                {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                <input type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} className="button--primary" /><br />
            </div>
        </div>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Login;