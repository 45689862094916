import React, {useState} from 'react';
import './Substitute.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMinusCircle, faRetweet, faKeyboard,faCameraRetro} from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import  Recommendations from "../Recommendations/Recommendations";
import Search from "../Search/Search";
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import {getScanProductApiEndPoint} from "../../Utils/Common";
import axios from "axios";
import {useHistory} from "react-router-dom";
import $ from "jquery";
import Zoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css';

const Substitute = props => {
    const {productItem} = props;
    const {productCategory} = props;
    const {orderItems} = props;
    const [showMessage, setShowMessage] = useState(false);
    const [substituteItems, setSubstituteItems] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const history = useHistory();

    const close = (e) => {
        document.getElementById("substitute").classList.remove("show");
        document.getElementById("order-items").classList.remove("hide");
        document.getElementById("order-items").classList.add("show");
        document.getElementById("substitute").classList.add("hide");
        document.getElementById("substitute-actions").classList.remove("hide");
        document.getElementById("picking-actions").classList.remove("hide");
        document.getElementById("order-comments").classList.remove("hide");
        let substituteElementExists = document.getElementById("alternate-actions");
        if(substituteElementExists){
            document.getElementById("alternate-actions").classList.remove("hide");
        }
    }
    const markAsUnavailable = (productItemId) => {
        confirmAlert({
            title: '',
            message: 'Are you sure this item is unavailable?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let productId = null;
                        orderItems.forEach((products , index) => {
                            let item = products.find(product => product.category === productCategory);
                            item.items.forEach((product, i) => {
                                if (product.order_item_id === productItemId) {
                                    product.unavailable = true;
                                    productId = product.product_id;
                                }
                            })
                        });
                        props.onChange(orderItems);
                        close();
                        document.getElementById('prod_banner_'+productId).classList.add("show");
                        document.getElementById('prod_banner_'+productId).classList.remove("hide");
                        document.getElementById('prod_details_'+productId).classList.add("hide");
                        document.getElementById('prod_details_'+productId).classList.remove("show");
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        let productId = null;
                        orderItems.forEach((products , index) => {
                            let item = products.find(product => product.category === productCategory);
                            item.items.forEach((product, i) => {
                                if (product.order_item_id === productItemId) {
                                    product.unavailable = false;
                                    productId = product.product_id;
                                }
                            })
                        });
                        props.onChange(orderItems);
                        close();
                        document.getElementById('prod_banner_'+productId).classList.add("show");
                        document.getElementById('prod_banner_'+productId).classList.remove("hide");
                        document.getElementById('prod_details_'+productId).classList.add("hide");
                        document.getElementById('prod_details_'+productId).classList.remove("show");
                    }
                }
            ]
        });
    }

    const addSubstitutions = (orderItems) => {
        props.onChange(orderItems);
    }

    const loadSubstitutions = (e,productId) =>{
        setSubstituteItems(productId);
        document.getElementById("substitute-actions").classList.add("hide");
        document.getElementById("alternate-actions").classList.remove("hide");
    }

    const loadSearch = (e,productId) =>{
        setShowSearch(productId);
        document.getElementById("substitute-actions").classList.add("hide");
        let substituteElementExists = document.getElementById("alternate-actions");
        if(substituteElementExists){
            document.getElementById("alternate-actions").classList.add("hide");
        }
        document.getElementById("substitute-item-details").classList.add("hide");
    }

    const addAlternative = (result) => {
        if(result){
            let productCode = result;
            const scanProductApiEndPoint = getScanProductApiEndPoint();
            axios.post(scanProductApiEndPoint, { productCode: productCode, }).then(response => {
                if(response.data.length){
                    addItem(response.data[0]);
                }
            }).catch(error => {
                if(error.response.data.message && error.response.data.message === "The consumer isn't authorized to access %resources."){
                    history.push("/login");
                }
            });
        }
    }

    const addItem = (updatedItem) => {
        orderItems.forEach(
            (products) => {
                let item = products.find(product => product.category === productCategory);
                let indexToUpdate = item.items.findIndex(obj => obj.product_id === productItem.product_id);
                let OrderItemToUpdate = item.items[indexToUpdate];
                let qtyToAdd = 0;
                if(updatedItem.product_id === productItem.product_id){
                    OrderItemToUpdate.qty_picked = OrderItemToUpdate.qty_picked+1;
                }
                else {
                    if (OrderItemToUpdate.product_id === productItem.product_id) {
                        if (OrderItemToUpdate.substitutelines[0]) {
                            qtyToAdd = OrderItemToUpdate.substitutelines[0].qty + 1;
                        }
                        let itemToAdd = {
                            name: updatedItem.name,
                            sku: updatedItem.sku,
                            price: updatedItem.price,
                            image_url: updatedItem.image_url,
                            product_code: updatedItem.product_code,
                            pack_size: updatedItem.pack_size,
                            product_id: updatedItem.product_id,
                            qty: qtyToAdd,
                            parent_id: productItem.product_id,
                            is_weighted: productItem.is_weighted
                        };
                        OrderItemToUpdate.substituted_qty = qtyToAdd;
                        OrderItemToUpdate.substitutelines[0] = itemToAdd;
                        if(OrderItemToUpdate.qty_picked + OrderItemToUpdate.substituted_qty >= OrderItemToUpdate.qty_ordered ){
                            OrderItemToUpdate.fully_picked = true
                        }
                        else{
                            OrderItemToUpdate.fully_picked = false
                        }
                    }
                }
            });
        props.onChange(orderItems);
        close();
    }

    const showScanner = () =>{
        setShowMessage(true);
        $(".alternate-scan").removeClass("fade");
        document.getElementsByClassName('substitute-alternative-input')[0].focus({
            preventScroll: true
        });
        setTimeout(function() {
            $('.substitute-alternative-input').attr('inputmode','text');
        }, 10);
        setTimeout(function() {
            setShowMessage(false);
        }, 1000);
    }

    $('.substitute-alternative-input').off("input change").on("input change", function(e) {
        let barcode = $('.substitute-alternative-input').val();
        if (barcode.length > 8 && barcode!=="") {
            addAlternative(barcode);
            setTimeout(function() {
                clearBarcode();
            }, 20);
        }
    });

    const clearBarcode = () => {
        $('.substitute-alternative-input').val("");
    }

    $('.substitute-alternative-input').on("blur", function() {
        $('.substitute-alternative-input').attr('inputmode','none');
        $(".alternate-scan").addClass("fade");
    });


    return (
        <div className="substitute-item">
            <span onClick={(e) => close()} className="close">Close</span>
            <div className="item-info" id="substitute-item-details">
                <div className="item-image">
                    <Zoom>
                        <img
                            alt={productItem.name}
                            src={productItem.image_url}
                            width="100"
                        />
                    </Zoom>
                </div>
                <div className="item-desc">
                    <div>
                        <span className="item-name">{productItem.name}</span><br/>
                        <span className="item-sku">Code: {productItem.product_code}</span><br/>
                        <span className="item-price">Pack Size: {productItem.pack_size}</span><br/>
                        <span className="item-price">Price: {productItem.item_price}</span><br/>
                        <span className="item-qty-ordered">Qty Ordered: {productItem.qty_ordered}</span><br/>
                        <span className="item-qty-ordered">Qty Picked: {productItem.qty_picked}</span>
                    </div>
                </div>
            </div>
            <div className="recommendations">
                {substituteItems && <Recommendations productItem = {productItem}  productCategory={productCategory} orderItems={orderItems} key="product_recommendations" onChange={addSubstitutions} />}
            </div>
            <div className="search sub-search">
                {showSearch && <Search productItem = {productItem}  productCategory={productCategory} orderItems = {orderItems} key="code_search" onChange={addSubstitutions}/>}
            </div>
            <div className="substitute-actions" id="substitute-actions">
                <div className="action" onClick={(e) => loadSubstitutions(e, productItem.product_id)}>
                    <FontAwesomeIcon size='5x' icon={faRetweet} />
                    <br/>
                    <span>Substitute</span>
                </div>
                <div className="action" onClick={(e) => markAsUnavailable(productItem.order_item_id)} >
                    <FontAwesomeIcon size='5x' icon={faMinusCircle} /><br/>
                    <span>Unavailable</span>
                </div>
            </div>
            <div className="alternate-actions hide" id="alternate-actions">
                <div className="action alternate-scan fade" onClick={() => showScanner()}>
                    <label>Scan Alternative</label>
                    <FontAwesomeIcon size='2x' icon={faCameraRetro} />
                </div>
                <div className="action" onClick={(e) => loadSearch(e, productItem.product_id)}>
                    <label>Enter Code</label>
                    <FontAwesomeIcon size='2x' icon={faKeyboard}/>
                </div>
            </div>
            <div>
                <input type="text" className="substitute-alternative-input hideScannerInput" autoFocus inputMode="none"/>
            </div>
            <Fade in={showMessage} >
                <Alert variant="filled" severity="success">
                    Ready to Scan!
                </Alert>
            </Fade>
        </div>
    );
}

export default Substitute;