import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {getProductSearchApiEndPoint, getToken} from "../../Utils/Common";
import axios from "axios";
import Zoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css';
const Search = props => {
    const {orderItems} = props;
    const {productItem} = props;
    const {productCategory} = props;
    const {addItem} = props;
    const {additionalProductsCategoryLabel} = props;
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = React.useState([]);
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };
    function close (e)  {
        document.getElementById("substitute").classList.remove("show");
        document.getElementById("order-items").classList.remove("hide");
        document.getElementById("order-items").classList.add("show");
        document.getElementById("substitute").classList.add("hide");
        document.getElementById("substitute-actions").classList.remove("hide");
        document.getElementById("picking-actions").classList.remove("hide");
    }
    useEffect(() => {
        const token = getToken();
        const apiUrl = getProductSearchApiEndPoint(searchTerm);
        setLoading(true);
        const getSearchResults = async () => {
            axios.get(
                apiUrl,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            ).then((response) => {
                setLoading(false);
                if(response.data && response.status === 200){
                    setSearchResults(response.data);
                }
                else{
                    setSearchResults([]);
                }
            });
        };
        getSearchResults();
    }, [searchTerm]);

    const addProduct = (updatedItem) => {
        if(productCategory === additionalProductsCategoryLabel){
            addItem(updatedItem);
        }else{
            orderItems.forEach(
                ( products ) => {
                    let item = products.find(product => product.category === productCategory);
                    let indexToUpdate = item.items.findIndex(obj => obj.product_id === productItem.product_id);
                    let OrderItemToUpdate = item.items[indexToUpdate];
                    let qtyToAdd = 1;
                    if (OrderItemToUpdate.product_id === productItem.product_id) {
                        let itemToAdd = {
                            name: updatedItem.name,
                            image_url: updatedItem.image_url,
                            price: updatedItem.price,
                            product_code: updatedItem.product_code,
                            product_id: updatedItem.product_id,
                            pack_size: updatedItem.pack_size,
                            qty: qtyToAdd,
                            parent_id: productItem.product_id,
                            is_weighted: updatedItem.is_weighted
                        };
                        OrderItemToUpdate.substituted_qty = qtyToAdd;
                        OrderItemToUpdate.substitutelines[0] = itemToAdd;
                        if(OrderItemToUpdate.qty_picked + OrderItemToUpdate.substituted_qty >= OrderItemToUpdate.qty_ordered ){
                            OrderItemToUpdate.fully_picked = true
                        }
                        else{
                            OrderItemToUpdate.fully_picked = false
                        }
                    }
                });
            props.onChange(orderItems);
            close();
        }

    }

    if (loading) {
        //return <p>Data is loading...</p>;
    }
    return (
        <div className="search">
            <label>Enter PLU/SKU</label>
            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
            />


        { searchResults.map((item, index) => (
            <div className="item-info" key={"search_item"+item.product_id}>
                <div className="topBanner show">
                    <div className="item-image">
                        <Zoom>
                            <img
                                alt={item.name}
                                src={item.image_url}
                                width="50"
                            />
                        </Zoom>
                    </div>
                    <div className="item-desc">
                        <div>
                            <span className="item-name">{item.name}</span><br/>
                            <span className="item-sku"> Code: {item.product_code}</span><br/>
                            <span className="item-price"> Pack Size: {item.pack_size}</span><br/>
                            <span className="item-price"> Price: {item.price}</span>

                        </div>
                    </div>
                    <div className="add-action">
                        {<div><span><FontAwesomeIcon size='2x' icon={faPlusCircle} className="button-add" onClick={(e) => addProduct(item) }/></span><label>Add</label></div>}
                    </div>
                </div>
            </div>
        ))}

        </div>
    );
}

export default Search;