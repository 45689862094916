import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import './AccordionOrderData.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEllipsisV,
    faExternalLinkAlt,
    faMinusSquare,
    faPlusSquare,
    faTimesCircle, faTrash,
    faLevelUpAlt, faCameraRetro
} from "@fortawesome/free-solid-svg-icons";
import {confirmAlert} from "react-confirm-alert";
import Substitute from "../Substitute/Substitute";
import {Then,Else, If} from 'react-if';
import $ from 'jquery';
import Alert from "@material-ui/lab/Alert";
import Fade from "@material-ui/core/Fade";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const AccordionOrderData = props => {
    const { orderItems } = props;
    const {substituteAllowed} = props;
    const [productItem, setProductItem] = useState();
    const [productCategory, setProductCategory] = useState();
    const [reloadKey, setReloadKey] = useState();
    const {additionalProductsCategoryLabel} = props;
    const [showMessage, setShowMessage] = useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        if(isExpanded){
            $(".order_intro").hide();
        }
        else{
            $(".order_intro").show();
        }
        setExpanded(isExpanded ? panel : false);
    };
    const toggle = (e,bannerId,detailId) =>{
        $(e.target).parents(".item-info").find("#"+detailId).toggleClass("show");
        $(e.target).parents(".item-info").find("#"+detailId).toggleClass("hide");
        $(e.target).parents(".item-info").find("#"+bannerId).toggleClass("hide");
        $(e.target).parents(".item-info").find("#"+bannerId).toggleClass("show");

    }

    const  updateQty = (e,productItem,mode,productType,itemCategory) => {
        orderItems.forEach(
            ( products ) => {
                let item = products.find(product => product.category === itemCategory);
                if(productType === "orderedItem"){
                    let indexToUpdate = "";
                    if(productItem.quote_item_id !== undefined){
                        indexToUpdate = item.items.findIndex(obj => obj.quote_item_id === productItem.quote_item_id);
                    }
                    else{
                        indexToUpdate = item.items.findIndex(obj => obj.product_id === productItem.product_id);
                    }
                    let OrderItemToUpdate = item.items[indexToUpdate];
                    if(mode === "increment"){
                        if(itemCategory === additionalProductsCategoryLabel && OrderItemToUpdate.product_id === productItem.product_id){
                            OrderItemToUpdate.qty_picked = OrderItemToUpdate.qty_picked + 1;
                        }
                        else if(OrderItemToUpdate.product_id === productItem.product_id && OrderItemToUpdate.qty_picked < OrderItemToUpdate.qty_ordered ){
                            OrderItemToUpdate.qty_picked = OrderItemToUpdate.qty_picked + 1;
                        }
                    }
                    if(mode === "decrement"){
                        if(itemCategory === additionalProductsCategoryLabel && OrderItemToUpdate.product_id === productItem.product_id){
                            OrderItemToUpdate.qty_picked = OrderItemToUpdate.qty_picked - 1;
                        }
                        else if(OrderItemToUpdate.product_id === productItem.product_id && OrderItemToUpdate.qty_picked >= 1 ){
                            OrderItemToUpdate.qty_picked = OrderItemToUpdate.qty_picked - 1;
                        }
                    }
                    setFullyPicked(OrderItemToUpdate);
                }
                if(productType === "subs"){
                    let indexToUpdate = item.items.findIndex(obj => obj.product_id === productItem.parent_id);
                    let OrderItemToUpdate = item.items[indexToUpdate];
                    if (OrderItemToUpdate.product_id === productItem.parent_id) {
                        if (OrderItemToUpdate.substitutelines[0]) {
                            let QtyToUpdate = OrderItemToUpdate.substitutelines[0].qty
                            if(mode === "increment"){
                                QtyToUpdate = QtyToUpdate+1;
                            }
                            if(mode === "decrement" && QtyToUpdate >= 1){
                                QtyToUpdate = QtyToUpdate-1;
                            }
                            OrderItemToUpdate.substitutelines[0].qty  = QtyToUpdate;
                            OrderItemToUpdate.substituted_qty = QtyToUpdate;
                        }
                    }
                    setFullyPicked(OrderItemToUpdate);
                }
            }
        );
        calculateRemainingItems(orderItems,itemCategory);
        props.onChange(orderItems);
    }

    const setFullyPicked = (productItem) =>{
        if(productItem.qty_picked + productItem.substituted_qty >= productItem.qty_ordered ){
            productItem.fully_picked = true
        }
        else{
            productItem.fully_picked = false
        }
    }


    const calculateRemainingItems = (orderItems,itemCategory) =>{
        if(itemCategory!==""){
            orderItems.forEach(
                ( products ) => {
                    let item = products.find(product => product.category === itemCategory);
                    const countTypes = item.items.filter(product => product.fully_picked === true);
                    item.remaining_items = item.total_items - countTypes.length;
                });
        }
        else{
            orderItems.forEach(
                ( products ) => {
                    products.forEach(
                        ( item ) => {
                            const countTypes = item.items.filter(product => product.fully_picked === true);
                            item.remaining_items = item.total_items - countTypes.length;
                        });
                });
        }
    }

    const substitute = (e,productId,productCategory) =>{
        orderItems.map((products, index) => (
            products.forEach((item, i) => {
                item.items.forEach((product, i) => {
                    if(product.product_id === productId){
                        setReloadKey(Math.floor(Math.random()*100));
                        setProductItem(product);
                        setProductCategory(productCategory);
                    }
                })
            })
        ));
        document.getElementById("substitute").classList.remove("hide");
        document.getElementById("substitute").classList.add("show");
        document.getElementById("order-items").classList.add("hide");
        document.getElementById("picking-actions").classList.add("hide");
        document.getElementById("order-comments").classList.add("hide");
    }


    const addQty = (qtyToAdd,productItem,itemIndex,productType,itemCategory,isWeightedProduct = false) => {
        orderItems.forEach(
            ( products ) => {
                let item = products.find(product => product.category === itemCategory);
                if(productType === "orderedItem"){
                    let OrderItemToUpdate = item.items[itemIndex];
                    let QtyOrdered = OrderItemToUpdate.qty_ordered;
                    if(!isWeightedProduct && itemCategory !== additionalProductsCategoryLabel && OrderItemToUpdate.product_id === productItem.product_id && qtyToAdd <= QtyOrdered  ) {
                        OrderItemToUpdate.qty_picked = qtyToAdd;
                    }
                    else if(isWeightedProduct && qtyToAdd > 0){
                        OrderItemToUpdate.qty_picked = QtyOrdered;
                        OrderItemToUpdate.weight_picked = qtyToAdd;
                    }
                    else if(qtyToAdd > QtyOrdered ){
                        OrderItemToUpdate.qty_picked = QtyOrdered;
                    }
                    else{
                        OrderItemToUpdate.qty_picked = qtyToAdd;
                    }
                    setFullyPicked(OrderItemToUpdate);
                }
                if(productType === "subs"){
                    let indexToUpdate = item.items.findIndex(obj => obj.product_id === productItem.parent_id);
                    let OrderItemToUpdate = item.items[indexToUpdate];
                    if (OrderItemToUpdate.product_id === productItem.parent_id) {
                        if (OrderItemToUpdate.substitutelines[0]) {
                            OrderItemToUpdate.substitutelines[0].qty  = qtyToAdd;
                            OrderItemToUpdate.substituted_qty = qtyToAdd;
                        }
                    }
                    setFullyPicked(OrderItemToUpdate);
                }
            }
        );
        calculateRemainingItems(orderItems,itemCategory);
        props.onChange(orderItems);
    }

    const deleteSubstitute = (e,substituteItem,itemCategory) => {
        confirmAlert({
            title: '',
            message: 'Are you sure you delete this substitute?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        orderItems.forEach(
                            ( products ) => {
                                let item = products.find(product => product.category === itemCategory);
                                let productId = substituteItem.parent_id;
                                let indexToUpdate = item.items.findIndex(obj => obj.product_id === productId);
                                let OrderItemToUpdate = item.items[indexToUpdate];
                                let lineIndex = OrderItemToUpdate.substitutelines.findIndex(el => el.product_id === substituteItem.product_id);
                                OrderItemToUpdate.substituted_qty = 0;
                                OrderItemToUpdate.substitutelines.splice(lineIndex, 1);
                                setFullyPicked(OrderItemToUpdate);
                            });
                        props.onChange(orderItems);
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const deleteProduct = (e,productItem,itemCategory) =>{
        confirmAlert({
            title: '',
            message: 'Are you sure you delete this product?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        orderItems.forEach(
                            ( products ) => {
                                let item = products.find(product => product.category === itemCategory);
                                let productId = productItem.product_id;
                                let indexToUpdate = item.items.findIndex(obj => obj.product_id === productId);
                                item.items.splice(indexToUpdate,1);
                            });
                        props.onChange(orderItems);
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const refreshOrderItems = (orderItems) => {
        props.onChange(orderItems);
    }

    const showScanner = () =>{
        setShowMessage(true);
        $(".scan").removeClass("fade");
        document.getElementsByClassName('scanner-input')[0].focus({
            preventScroll: true
        });
        setTimeout(function() {
            $('.scanner-input').attr('inputmode','text');
        }, 10);
        setTimeout(function() {
            setShowMessage(false);
        }, 1000);
    }


    return (
        <div>
        <div id="order-items">
            {orderItems.map((products, index) => (
                <div className="test" key={'order_cat'+index}>
                    {products.map((item, index) => (
                        <Accordion key={'acc_item_'+index} className="order-table" expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={item.category===additionalProductsCategoryLabel? 'bg-amber' : item.remaining_items >0  ? 'bg-red' : 'bg-green'}
                            >
                                <Typography className="cat-title">{item.category}
                                    <If condition={item.category!==additionalProductsCategoryLabel}>
                                        <span>({item.total_items}{ item.total_items > 1 ? " Items" : " Item"} | {item.remaining_items} Remaining)</span>
                                    </If>
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails className="product-outer-wrap">
                                {item.items.map((productItem, i) => (
                                    <Typography component={'div'} className="product-items" key={'acc_content_'+i}>
                                            <div className="item-info">
                                                <div className="topBanner show" id={'prod_banner_' + productItem.product_id} onClick={(e) => toggle(e, 'prod_banner_' + productItem.product_id, 'prod_details_' + productItem.product_id)}>
                                                    <div className="item-desc">
                                                        <div>
                                                            <span className="item-name">{productItem.name}</span>
                                                            <br/>
                                                            <span className="item-sku">Code: {productItem.product_code}</span>
                                                        </div>
                                                    </div>
                                                    <div className="item-picking">
                                                        <div className={productItem.unavailable === true ? 'red' : ''}><span className="status">{productItem.unavailable === true ? "Marked as Unavailable" : ""}</span></div>
                                                        <div className={productItem.fully_picked ? 'green' : 'red'}>
                                                            <span className="picked-label">Picked:</span>
                                                            {item.category===additionalProductsCategoryLabel? productItem.qty_picked : productItem.fully_picked ? productItem.qty_ordered + " / " + productItem.qty_ordered : productItem.qty_picked + " / " + productItem.qty_ordered} </div>
                                                    </div>
                                                    <div className="item-action">
                                                        <FontAwesomeIcon size='2x' icon={faExternalLinkAlt}/>
                                                    </div>
                                                </div>
                                                <div className="details hide" id={'prod_details_' + productItem.product_id}>
                                                    <div>
                                                        <div className="item-image">
                                                            <Zoom>
                                                                <img
                                                                    alt={productItem.name}
                                                                    src={productItem.image_url}
                                                                    width="100"
                                                                />
                                                            </Zoom>
                                                        </div>
                                                        <div className="item-content">
                                                            <div className="item-desc">
                                                                <span className="item-name">{productItem.name}</span>
                                                                <br/>
                                                                <span className="item-sku">Code: {productItem.product_code}</span>
                                                                <br/>
                                                                <span className="item-price">Pack Size: {productItem.pack_size}</span>
                                                                <br/>
                                                                <span className="item-price">Price: {productItem.purchase_unit==="Weight" ? productItem.item_price + " (" + productItem.unit_price  + "/" + productItem.pack_unit + ") " : productItem.item_price }</span>
                                                                <br/>
                                                                <If condition={productItem.customer_note}>
                                                                    <Then>
                                                                        <span className="item-note">Customer Note: {productItem.customer_note}</span>
                                                                        <br/>
                                                                    </Then>
                                                                </If>
                                                                <If condition={productItem.purchase_unit==="Weight"}>
                                                                    <Then>
                                                                        <span className="item-unit">Weight Ordered: { productItem.weight + productItem.pack_unit }</span>
                                                                        <br/>
                                                                    </Then>
                                                                    <Else>
                                                                        <span className="item-qty-ordered">{item.category===additionalProductsCategoryLabel ? "Qty Picked:" + productItem.qty_picked : "Qty Ordered:" + productItem.qty_ordered }</span>
                                                                        <br/>
                                                                    </Else>
                                                                </If>
                                                                <div className="item-qty">
                                                                    <If condition={productItem.is_weighted_item || (productItem.purchase_unit==="Weight" && productItem.weight!== "")}>
                                                                        <Then>
                                                                            <div className="weighted-quantity">
                                                                                Weight Picked:&nbsp;<input type="text" id={'todoName' + productItem.product_id} defaultValue={productItem.weight_picked} onChange={e => addQty((parseFloat(e.target.value) || 0),productItem,i,"orderedItem",item.category,true)} className="weight-input"/>
                                                                            </div>
                                                                        </Then>
                                                                        <Else>
                                                                            <div className="add-minus-quantity">
                                                                                <FontAwesomeIcon size='2x' className="inc" icon={faPlusSquare} onClick={(e) => updateQty(e, productItem, "increment", "orderedItem",item.category)}/>
                                                                                <input type="text" id={'todoName' + productItem.product_id} value={productItem.qty_picked} onChange={e => addQty((parseFloat(e.target.value) || 0),productItem,i,"orderedItem",item.category,false)} className="qty-input"/>
                                                                                <FontAwesomeIcon size='2x' className="inc" icon={faMinusSquare} onClick={(e) => updateQty(e, productItem, "decrement", "orderedItem",item.category)}/>
                                                                            </div>
                                                                        </Else>
                                                                    </If>
                                                                </div>
                                                                <div className="scan fade" onClick={() => showScanner()}>
                                                                    <label>Scan</label>
                                                                    <FontAwesomeIcon size='2x' icon={faCameraRetro}/>
                                                                </div>
                                                                <Fade in={showMessage} >
                                                                    <Alert variant="filled" severity="success">
                                                                        Ready to Scan!
                                                                    </Alert>
                                                                </Fade>
                                                            </div>

                                                            <div className="item-action">
                                                                <div>
                                                                    <If condition={item.category === additionalProductsCategoryLabel}>
                                                                        <Then>
                                                                            <FontAwesomeIcon size='2x' icon={faTrash} onClick={(e) => deleteProduct(e, productItem,item.category)}/>
                                                                        </Then>
                                                                        <Else>
                                                                            <FontAwesomeIcon size='2x' icon={faEllipsisV} onClick={(e) => substitute(e, productItem.product_id,item.category)}/>
                                                                        </Else>
                                                                    </If>
                                                                </div>
                                                                <div>
                                                                    <FontAwesomeIcon size='2x' icon={faTimesCircle} onClick={(e) => toggle(e, 'prod_banner_' + productItem.product_id, 'prod_details_' + productItem.product_id)}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="subs">
                                                        {productItem.substitutelines && productItem.substitutelines.map((subsItem, i) => (
                                                            <div className="ddf"  key={'subs_item_'+i}>
                                                                <FontAwesomeIcon size='2x' icon={faLevelUpAlt} />

                                                                <div className="item-image">
                                                                    <Zoom>
                                                                        <img
                                                                            alt={subsItem.name}
                                                                            src={subsItem.image_url}
                                                                            width="100"
                                                                        />
                                                                    </Zoom>
                                                                </div>
                                                                <div className="item-desc">
                                                                    <span className="item-name">{subsItem.name}</span>
                                                                    <br/>
                                                                    <span className="item-sku">Code: {subsItem.product_code}</span>
                                                                    <br/>
                                                                    <span className="item-price">Pack Size: {subsItem.pack_size}</span>
                                                                    <br/>
                                                                    <span className="item-price">Price: {subsItem.price}</span>
                                                                    <br/>
                                                                    <div className="item-qty">
                                                                        <If condition={subsItem.is_weighted}>
                                                                            <Then>
                                                                                <div className="weighted-quantity">
                                                                                    Weight Picked:&nbsp;<input type="text" onChange={e => addQty((parseFloat(e.target.value) || 0),subsItem,i,"subs",item.category,true)} className="weight-input"/>
                                                                                </div>
                                                                            </Then>
                                                                            <Else>
                                                                                <div className="add-minus-quantity">
                                                                                    <FontAwesomeIcon size='2x' className="inc" icon={faMinusSquare} onClick={(e) => updateQty(e, subsItem, "decrement", "subs",item.category)}/>
                                                                                    <input type="text" placeholder={subsItem.qty} value={subsItem.qty} onChange={e => addQty( (parseInt(e.target.value) || 0),subsItem,i,"subs",item.category)} className="qty-input"/>
                                                                                    <FontAwesomeIcon size='2x' className="inc" icon={faPlusSquare} onClick={(e) => updateQty(e, subsItem, "increment", "subs",item.category)}/>
                                                                                </div>
                                                                            </Else>
                                                                        </If>
                                                                    </div>
                                                                    <div className="scan fade" onClick={() => showScanner()}>
                                                                        <label>Scan</label>
                                                                        <FontAwesomeIcon size='2x' icon={faCameraRetro}/>
                                                                    </div>
                                                                    <Fade in={showMessage} >
                                                                        <Alert variant="filled" severity="success">
                                                                            Ready to Scan!
                                                                        </Alert>
                                                                    </Fade>
                                                                </div>
                                                                <div className="item-action">
                                                                    <div>
                                                                        <FontAwesomeIcon size='2x' icon={faTrash} onClick={(e) => deleteSubstitute(e, subsItem,item.category)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                    </Typography>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            ))}
        </div>
            <div id="substitute" className="substitute hide">
                {productItem && <Substitute productItem={productItem} productCategory={productCategory} orderItems={orderItems} key={reloadKey} onChange={refreshOrderItems} substituteAllowed={substituteAllowed}/>}
            </div>
        </div>
    );
}

export default AccordionOrderData;