import React, {useEffect, useState} from 'react';
import {getCustomerDetailsApiEndPoint, getToken} from "../../Utils/Common";
import axios from "axios";
import './Customer.css';


const Customer = props => {
    const { orderId } = props;
    const [customerDetails, setCustomerDetails] = useState([]);
    useEffect(() => {
        const token = getToken();
        let id = orderId;
        const apiUrl = getCustomerDetailsApiEndPoint(id);
        const getCustomerInfo = async () => {
            axios.get(
                apiUrl,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            ).then((response) => {
                if(response.data){
                    setCustomerDetails(response.data);
                }
            });
        };
        getCustomerInfo();
    }, [orderId]);

    return (

        <div id="customer-details">
            <div className="customer-Info">
                {customerDetails.map((customer, index) => (
                    <div className="details" key={"cus_dtl_"+index}>
                        <span>Customer: {customer.name}</span>
                        <span>Email: {customer.email}</span>
                        <span>Tel: {customer.phone}</span>
                    </div>
                 ))}
            </div>
        </div>
    );
}
export default Customer;