import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import {Link} from "@material-ui/core";
import AccordionOrderData from "../AccordionOrderData/AccordionOrderData";
import OrderComments from "../OrderComments/OrderComments";
import PickingActions from "../PickingActions/PickingActions";
import AddAdditionalProducts from "../AddAdditionalProducts/AddAdditionalProducts";
import {useHistory, useParams} from "react-router-dom";
import {getAdminId, getLoadOrderApiEndPoint, getParkOrderApiEndPoint, getPickingId, getToken} from "../../Utils/Common";
import {trackPromise} from "react-promise-tracker";
import './Pick.css';
import axios from "axios";
import useForceUpdate from "use-force-update";
import {confirmAlert} from "react-confirm-alert";
import {Else, If, Then} from "react-if";
import Loader from "react-loader-spinner";

function Pick() {

    const {id , category} = useParams();
    const history = useHistory();
    const forceUpdate = useForceUpdate();
    const [orderItems, setOrderItems] = useState([]);
    const [orderComments, setOrderComments] = useState([]);
    const [substituteAllowed, setSubstituteAllowed] = useState(false);
    const [showProductsScreen, setShowProductsScreen] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const token = getToken();
        const pickerId = getAdminId();
        let orderId = id;
        let pickingCategory = category;
        const apiUrl = getLoadOrderApiEndPoint(orderId);
        const getOrder = async () => {
            trackPromise(
                axios.get(
                    apiUrl,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                ).then((response) => {
                    if(response.data){
                        let responseData = response.data;
                        responseData.forEach((data, index) => {
                            if (data.picking_category === pickingCategory) {
                                if((data.is_locked && data.picker_id!== pickerId )|| (data.picking_completed)){
                                    history.push(`/category/${id}`);
                                }
                                setSubstituteAllowed(data.allow_substitution);
                                setOrderComments(data.order_comment);
                                setOrderItems([data.products]);
                            }
                        });
                    }
                }).catch(error => {
                    let errorMessage = error.response.data.message;
                    if(errorMessage === "The consumer isn't authorized to access %resources."){
                        history.push("/login");
                    }
                })
            )
        };
        getOrder();
    }, [id,category,history]);

    function refreshOrderItems(orderItems) {
        calculateRemainingItems(orderItems,"");
        setOrderItems(orderItems);
        forceUpdate();
    }

    const calculateRemainingItems = (orderItems,itemCategory) =>{
        if(itemCategory!==""){
            orderItems.forEach(
                ( products ) => {
                    let item = products.find(product => product.category === itemCategory);
                    const countTypes = item.items.filter(product => product.fully_picked === true);
                    item.remaining_items = item.total_items - countTypes.length;
                });
        }
        else{
            orderItems.forEach(
                ( products ) => {
                    products.forEach(
                        ( item ) => {
                            const countTypes = item.items.filter(product => product.fully_picked === true);
                            item.remaining_items = item.total_items - countTypes.length;
                        });
                });
        }
    }

    const back = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to exit the order?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        const parkOrderApiEndPoint = getParkOrderApiEndPoint();
                        axios.post(parkOrderApiEndPoint, { orderId:id,pickerId:getAdminId(),orderData: orderItems,pickingId:getPickingId(),pickingCategory:category }).then(response => {
                            if(response.data){
                                setLoader(false);
                                history.push(`/category/${id}`);
                            }
                        }).catch(error => {
                            if(error.response && error.response.data.message && error.response.data.message === "The consumer isn't authorized to access %resources."){
                                history.push("/login");
                            }
                            else{
                                setLoader(false);
                                history.push(`/category/${id}`);
                            }
                        });
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const addProducts = () => {
        document.getElementById('section_order_data').classList.add("hide");
        setShowProductsScreen(true);
    }

    function addAdditionalRequests(orderItems) {
        setOrderItems(orderItems);
        forceUpdate();
    }

    function close() {
        document.getElementById('section_order_data').classList.remove("hide");
        setShowProductsScreen(false);
    }

    return (
        <div>
            <Header/>
            <If condition={loader}>
                <Then>
                    <Loader type="ThreeDots" color="#008863" height="100" width="100"  className="loading-spinner" />
                </Then>
                <Else>
                    <div>
                        <div className="order_intro">
                            <div className="title__section">
                                <div className="title__inner">
                                    <p>Order: #{id}</p><br/>
                                    <Link onClick={() => back()} className="button--secondary">Back</Link>
                                    <Link onClick={() => addProducts()} className="button--secondary">Add Products</Link>
                                </div>
                            </div>
                            <div className="title__subs"><span>Substitution Allowed: {substituteAllowed ? "Yes": "No"} </span></div>
                        </div>
                        <div id="section_order_data">
                            <AccordionOrderData orderItems={orderItems} onChange={refreshOrderItems} substituteAllowed={substituteAllowed} additionalProductsCategoryLabel={"Additional Products"}/>
                            <OrderComments orderComments={orderComments}/>
                            <PickingActions orderItems={orderItems} orderId={id} pickingCategory={category} onChange={refreshOrderItems}/>
                        </div>
                        <div className="section_add_products">
                            {showProductsScreen && <AddAdditionalProducts orderId={id} orderItems={orderItems} onClick={(e) => close()} onChange={addAdditionalRequests} additionalProductsCategoryLabel={"Additional Products"}/>}
                        </div>
                    </div>
                </Else>
            </If>
        </div>
    )
};

export default Pick;