import React from 'react';
import './OrderComments.css';

const OrderComments = props => {
    const { orderComments } = props;
    return (
        <div id="order-comments">
            <span>Order Comments</span>
            <div className="order-comments__inner">{orderComments}</div>
        </div>
    )
}

export default OrderComments;